document.addEventListener('turbo:load', function() {
    setupAddRemoveFields();
});

document.addEventListener('turbo:load', function() {
    const fileInput = document.getElementById('file-upload');

    if (fileInput) {
        fileInput.addEventListener('change', function(event) {
            const file = event.target.files[0];
            if (file) {
                document.getElementById('document_original_file_name').value = file.name;
                document.getElementById('document_file_type').value = file.type || '不明';
                document.getElementById('document_file_size').value = (file.size / 1024).toFixed(2) + ' KB';
                document.getElementById('document_last_modified_at').value = new Date(file.lastModified).toLocaleString();
            }
        });
    }
});

document.addEventListener('turbo:load', function() {
    const fileAttributeGroupSelect = document.querySelector('[name="document[file_attribute_group_id]"]');
    const fileAttributeFields = document.querySelectorAll('.document-file-attribute');

    // 新規登録の場合は、初期表示時にすべてのフィールドを非表示にする
    if (!window.location.pathname.match(/^\/account\/documents\/[^\/]+\/edit$/)) {
        fileAttributeFields.forEach(function(field) {
            field.style.display = 'none';
            field.querySelector('[name*="[file_attribute_id]"]').disabled = true;
            field.querySelector('[name*="[name]"]').disabled = true;
            field.querySelector('[name*="[value]"]').disabled = true;
        });
    } else {
        // 編集の場合は、初期表示時に初期値のgroup_idでフィルターする
        const initialGroupId = document.getElementById('document_file_attribute_group_id').value;
        const nestedFields = document.querySelectorAll('.nested-fields');

        nestedFields.forEach(function(nestedField) {
            const fileAttributeFields = nestedField.querySelectorAll('.document-file-attribute');
            fileAttributeFields.forEach(function(field) {
                const fileAttributeGroupId = field.getAttribute('data-file-attribute-group-id');
                if (fileAttributeGroupId !== initialGroupId) {
                    field.style.display = 'none';
                    field.querySelector('[name*="[file_attribute_id]"]').disabled = true;
                    field.querySelector('[name*="[name]"]').disabled = true;
                    field.querySelector('[name*="[value]"]').disabled = true;
                } else {
                    field.style.display = 'none';
                    field.querySelector('[name*="[file_attribute_id]"]').disabled = true;
                    field.querySelector('[name*="[name]"]').disabled = true;
                    field.querySelector('[name*="[value]"]').disabled = true;
                }
            });
        });
    }

    if (fileAttributeGroupSelect) {
        fileAttributeGroupSelect.addEventListener('change', function(event) {
            const selectedGroupId = event.target.value;
            console.log("Selected file attribute group ID:", selectedGroupId);

            // フィルタリングの処理
            fileAttributeFields.forEach(function(field) {
                const fileAttributeGroupId = field.getAttribute('data-file-attribute-group-id');
                const shouldDisplay = fileAttributeGroupId === selectedGroupId;

                if (shouldDisplay) {
                    field.style.display = '';
                    field.querySelector('[name*="[file_attribute_id]"]').disabled = false;
                    field.querySelector('[name*="[name]"]').disabled = false;
                    field.querySelector('[name*="[value]"]').disabled = false;
                } else {
                    field.style.display = 'none';
                    field.querySelector('[name*="[file_attribute_id]"]').disabled = true;
                    field.querySelector('[name*="[name]"]').disabled = true;
                    field.querySelector('[name*="[value]"]').disabled = true;
                }
            });
        });
    }
});


function setupAddRemoveFields() {
    document.querySelectorAll('.add_fields').forEach(function(addFieldButton) {
        addFieldButton.addEventListener('click', function(e) {
            e.preventDefault();
            const time = new Date().getTime();  // 一意なインデックスを生成する

            // 動的にテンプレートを生成する
            const template = `
              <div class="flex document-file-attribute w-full justify-start align-center space-x mt-4">
                <input type="text" name="document[document_file_attributes_attributes][${time}][name]" placeholder="カスタムファイル属性" class="block w-50 rounded-md shadow-sm font-light dark:bg-slate-800 dark:text-slate-300 text-base md:text-sm disabled:bg-slate-200 disabled:dark:bg-slate-700 focus:ring-primary-500 focus:border-primary-500 border-slate-300 dark:border-slate-900">
                <input type="text" name="document[document_file_attributes_attributes][${time}][value]" placeholder="カスタムファイル値"  class="block w-50 rounded-md shadow-sm font-light dark:bg-slate-800 dark:text-slate-300 text-base md:text-sm disabled:bg-slate-200 disabled:dark:bg-slate-700 focus:ring-primary-500 focus:border-primary-500 border-slate-300 dark:border-slate-900">
                <button type="button" class="remove_fields text-red-500 hover:text-red-700"><i class="ti ti-trash"></i></button>
              </div>
            `;

            // 挿入する場所に追加
            this.closest('.custom-nested-fields').insertAdjacentHTML('beforeend', template);
        });
    });

    document.addEventListener('click', function(e) {
        if (e.target.closest('.remove_fields')) {
            e.preventDefault();
            e.target.closest('.document-file-attribute').remove();
        }
    });
}

document.addEventListener('turbo:load', function () {
    const dropArea = document.getElementById('drop-area');
    const fileInput = document.getElementById('file-upload');

    // ファイルがドラッグされたときの処理
    dropArea.addEventListener('dragover', (event) => {
        event.preventDefault();
        dropArea.classList.add('bg-gray-100');
    });

    // ドラッグが離れたときの処理
    dropArea.addEventListener('dragleave', () => {
        dropArea.classList.remove('bg-gray-100');
    });

    // ファイルがドロップされたときの処理
    dropArea.addEventListener('drop', (event) => {
        event.preventDefault();
        dropArea.classList.remove('bg-gray-100');
        const files = event.dataTransfer.files;
        fileInput.files = files;
        // ファイル情報を表示する関数を呼び出す
        displayFileInfo(files[0]);
    });

    // クリックでファイル選択
    dropArea.addEventListener('click', () => {
        fileInput.click();
    });

    // ファイル情報を表示する関数
    function displayFileInfo(file) {
        const fileNameField = document.querySelector('input[name="document[original_file_name]"]');
        const fileTypeField = document.querySelector('input[name="document[file_type]"]');
        const fileSizeField = document.querySelector('input[name="document[file_size]"]');
        const lastModifiedField = document.querySelector('input[name="document[last_modified_at]"]');

        fileNameField.value = file.name;
        fileTypeField.value = file.type;
        fileSizeField.value = (file.size / 1024).toFixed(2) + ' KB';
        lastModifiedField.value = new Date(file.lastModified).toLocaleString();
    }
});
