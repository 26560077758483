document.addEventListener('turbo:load', function () {
    // デスクトップメニューの全ての項目に対してクリックイベントを設定
    document.querySelectorAll('[data-controller="desktop-menu"]').forEach(function (menu) {
        menu.addEventListener('click', function () {
            const submenu = menu.querySelector('.submenu');
            const arrowIcon = menu.querySelector('.arrow-icon i');

            if (submenu) {
                if (submenu.style.maxHeight && submenu.style.maxHeight !== '0px') {
                    submenu.style.maxHeight = '0';  // サブメニューを閉じる
                    arrowIcon.classList.remove('ti-angle-up');  // 上向き矢印を削除
                    arrowIcon.classList.add('ti-angle-down');  // 下向き矢印を追加
                } else {
                    submenu.style.maxHeight = submenu.scrollHeight + 'px';  // サブメニューを開く
                    arrowIcon.classList.remove('ti-angle-down');  // 下向き矢印を削除
                    arrowIcon.classList.add('ti-angle-up');  // 上向き矢印を追加
                }
            }
        });
    });
});
